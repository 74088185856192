import _ from 'lodash'
import { PENDING, UNINITIALISED } from '../../middleware/redux-promise'
import { getLocation } from 'connected-react-router'
import { matchPath } from 'react-router'
import { createSelector } from 'reselect'
import moment from 'moment'
import fp from 'lodash/fp'
export const getIsUninitialised = state => {
  return state.reportDetails.status === UNINITIALISED
}
export const getIsLoading = state => {
  return state.reportDetails.status === PENDING
}
export const getIsLoadingForAck = state => !state.reportDetails.report || state.reportDetails.status === PENDING

export const getError = state => state.reportDetails.error
export const getStatus = state => state.reportDetails.status
export const getDetails = state => state.reportDetails.fields
export const getReportJSON = state => _.get(state, 'reportDetails.report.reportJSON', {})
export const getReportStatus = state => _.get(state, 'reportDetails.report.status')
export const getReportType = state => _.get(state, 'reportDetails.report.type')
export const getReport = state => _.get(state, 'reportDetails.report')
export const getReportId = state => _.get(state, 'reportDetails.report.id')
export const getExtReportId = state => _.get(state, 'reportDetails.report.extReportId')
export const getReportUserId = state => _.get(state, 'reportDetails.report.userId')
export const getOtherVersions = state => _.get(state, 'reportDetails.report.otherVersions', [])
export const getHasXML = state => _.get(state, 'reportDetails.report.hasXML', false)
export const getHasJSON = state => _.get(state, 'reportDetails.report.hasJSON', false)
export const getFromRegulatoryAgency = state => _.get(state, 'reportDetails.report.fromRegulatoryAgency', false)
export const getFormVersion = state => _.get(state, 'reportDetails.report.formViewVersion.formViews.versionId', 1)
export const getRevision = state => _.get(state, 'reportDetails.report.revision')
export const getIsReportEditable = state => _.get(state, 'reportDetails.report.editable')

export const getReassignmentStatus = state => _.get(state, 'reportDetails.reassignment.status')
export const getReassignmentError = state => _.get(state, 'reportDetails.reassignment.error.message')

/* Notification */
export const followUpNotificationState = state => _.get(state.reportDetails, 'selectedReportFollowupNotification')
export const getSelectedFollowUpNotificationError = createSelector(
  [followUpNotificationState],
  followUpNotification => _.get(followUpNotification, 'error')
)
export const getSelectedFollowupNotification = createSelector(
  [followUpNotificationState],
  followUpNotification => _.get(followUpNotification, 'result')
)

/**
 * Returns true if the report was created in Vigilance Hub.
 */
export const getIsReportArchived = state => !!_.get(state, 'reportDetails.report.archivedAt')
export const getHasAttachments = state => _.get(state, 'reportDetails.report.hasAttachments', false)
export const getHasEnhancements = state => _.get(state, 'reportDetails.report.hasEnhancements', false)
export const getEnhancements = state => _.get(state, 'reportDetails.report.enhancements', {})
export const getHasAssessmentSchemaItems = state => _.get(state, 'reportDetails.report.hasAssessmentSchemaItems', false)
export const getHasAttachmentSummary = state => _.get(state, 'reportDetails.report.hasAttachmentSummary', false)
/**
 * @type {(state: any) => boolean}
 * @description Check if the report generated a JSON representation as opposed to or in conjunction with XML.
 * Is applicable to form schemas like Trend, PSR, SAE and R3 Defect.
 */
export const getHasJSONReportExport = fp.getOr(false, 'reportDetails.report.hasJSONReportExport')

export const getXMLEditor = state => _.get(state, 'reportDetails.xmlEditor')

export const getExistingReport = state => _.get(state, 'reportDetails.existingReport')

export const getHasNewerVersion = createSelector([
  getOtherVersions,
  getReport
], (otherVersions, report) => {
  return _.find(otherVersions, otherVersion => {
    return moment(otherVersion.createdAt).isAfter(moment(report.updatedAt))
  })
})

export const getReportFieldsForAck = createSelector([
  getReportJSON
], reportJSON => {
  const reportDetails = _.pickBy({
    icsrmessagenumb: reportJSON.messagenumb,
    icsrmessagesenderidentifier: reportJSON.messagesenderidentifier,
    icsrmessagereceiveridentifier: reportJSON.messagereceiveridentifier,
    icsrmessagedate: reportJSON.messagedate,
    safetyreportid: reportJSON.safetyreportid,
    safetyreportversion: reportJSON.safetyreportversion,
    authoritynumb: reportJSON.authoritynumb,
    companynumb: reportJSON.companynumb,
    receiptdate: reportJSON.receiptdate
  })
  return _.isEmpty(reportDetails) ? undefined : reportDetails
})

export const getCanExportPdf = createSelector([
  getHasJSON,
  getIsReportArchived,
  getReportStatus,
  getHasAttachmentSummary
], (hasJSON, reportIsArchived, reportStatus, hasAttachmentSummary) => {
  return hasAttachmentSummary || (hasJSON && !reportIsArchived && reportStatus !== 'draft')
})

export const getAttachments = createSelector([
  getReport
], report => {
  const attachments = _.get(report, 'attachments', [])
  return _.map(attachments, ({
    attachmentType,
    attachmentfile,
    attachmenturl,
    attachmentTitle,
    resourceURI,
    status,
    id
  }) => ({
    isLegacy: !_.isUndefined(attachmenturl),
    title: attachmentfile || attachmentTitle || attachmenturl?.name || resourceURI,
    ext: fp.compose(
      fp.last,
      fp.split('.'),
      fp.defaultTo('')
    )(attachmenturl?.name || resourceURI),
    status: status || 'COMPLETE',
    attachmenturl,
    attachmentId: id,
    attachmentType
  })
  )
})

export const getReportWorkflowType = createSelector(
  [
    getReport
  ],
  fp.get('workflowType')
)

export const isReadOnlyReport = createSelector(
  [
    getReportWorkflowType
  ],
  fp.eq('READ_ONLY')
)

/**
 * @description `true` if the modal opened is already on the report details page / report form for the given report.
 *
 * An example of this is read only pages showing the modal instead of an edit button, so the user can download the PDF,
 * start from a report prompt, etc.
 */
export const isOnSelectedReportDetailsPage = createSelector([
  getLocation,
  getReport
], (location, report) => {
  const reportId = fp.getOr(null, 'id', report)
  if (!reportId) {
    return false
  }
  const match = matchPath(location.pathname, { path: '/reports/:reportId', exact: false })
  return fp.propEq('params.reportId', reportId)(match)
})

/**
 * @description Get the notification associated to the report, if at least one is provided.
 *
 * In the future this should display a modal of all associated reports,
 * but for a basic implementation it'll get the first and only.
 */
export const getNotificationIdForReport = createSelector([
  getReport
], (report) => {
  return fp.compose(
    fp.getOr(null, 'notificationId'),
    fp.first,
    fp.get('notificationReports')
  )(report)
})

export const isReadOnlyReportWithNotification = createSelector([
  isReadOnlyReport,
  getNotificationIdForReport
], (isReadOnlyReport, getNotificationIdForReport) => {
  return isReadOnlyReport && !!getNotificationIdForReport
})

export const getFormSchemaName = state => _.get(state, 'reportDetails.report.formSchema.name')
