import _ from 'lodash'
import userAddressSchema from '../../../components/Form/commonSchemas/userAddress'
import * as validators from '../../../validators'
import { titles, translations, apps, dashboard, countriesDirectory } from '../../../config'
import { permissionsLookup } from '../../../ability/ability-context'

const smallTextLengthValidator = validators.maxLength(50, 'Text')

export const personal = ({ allFieldsMandatory = false, canEditUserEmail = true } = {}) => {
  return [{
    id: 'title',
    field: 'Dropdown',
    props: {
      label: 'Title',
      name: 'title',
      options: titles,
      validate: [smallTextLengthValidator],
      shrink: true,
      required: false,
    }
  }, {
    id: 'firstName',
    field: 'Input',
    props: {
      label: 'First Name',
      name: 'firstName',
      validate: [smallTextLengthValidator],
      required: true,
      shrink: true,
    }
  }, {
    id: 'lastName',
    field: 'Input',
    props: {
      label: 'Last Name',
      name: 'lastName',
      validate: [smallTextLengthValidator],
      required: true,
      shrink: true,
    }
  }, {
    id: 'email',
    field: 'Email',
    props: {
      label: 'Email',
      name: 'email',
      validate: [validators.email, smallTextLengthValidator],
      required: true,
      shrink: true,
      disabled: !canEditUserEmail
    }
  },
  {
    id: 'telephoneCountryCode',
    field: 'Dropdown',
    props: {
      label: translations('Telephone Country Code'),
      name: 'telephoneCountryCode',
      validate: [
        validators.requiredWith({ fieldWith: 'telephone', withName: translations('Telephone'), fieldName: translations('Telephone Country Code') })
      ],
      options: countriesDirectory,
      shrink: true,
      required: false,
    }
  },
  {
    id: 'telephone',
    field: 'Input',
    props: {
      label: translations('Telephone'),
      name: 'telephone',
      validate: [
        validators.simplePhoneNumber,
        validators.requiredWith({ fieldWith: 'telephoneCountryCode', withName: translations('Telephone Country Code'), fieldName: translations('Telephone') })
      ],
      shrink: true,
      required: false,
    }
  },
  {
    id: 'telephoneExtension',
    field: 'Input',
    props: {
      label: translations('Telephone Extension'),
      name: 'telephoneExtension',
      validate: [validators.numeric],
      required: false,
      shrink: true,
    }
  },
  ...userAddressSchema({ allFieldsMandatory })
  ]
}

export const permissions = ({ hasReportAuditEnabled, ability, isCurrentUserAndSelectedUserOrgLead } = {}) => {
  const canAccessReportAudit = (hasReportAuditEnabled && (ability && ability.can('view', 'reportAudit')))
  const canAccessResendTransmissions = (ability && ability.can('resendTransmissions', 'caseManagement'))
  const canAccessReassignReports = (ability && ability.can('reassignReports', 'reportManagement'))
  const canEditPermissionsEditor = (ability && ability.can('editPermissions', 'permissionsEditor'))
  const canViewPermissions = (ability && ability.can('view', 'permissionsEditor'))
  const canUploadResource = (ability && ability.can('uploadResource', 'resources'))
  const canDeleteResource = (ability && ability.can('deleteResource', 'resources'))

  let schema = _.map(dashboard, tile => {
    const title = _.get(apps, [tile.id, 'title'])
    const fieldId = _.get(permissionsLookup, tile.id) + '-view'
    const tileName = _.get(permissionsLookup, tile.id)
    const label = translations(
      `Permissions Dropdown`,
      {
        tile: translations(`App Name - ${title}`),
        interpolation: { escapeValue: false }
      }
    )
    const options = [
      { value: 'DENY', label: 'Deny' },
      { value: 'DEFAULT', label: 'Default' }
    ]
    if (ability && ability.can('view', tileName)) {
      options.push({ value: 'ALLOW', label: 'Allow' })
    }
    return {
      id: fieldId,
      field: 'Dropdown',
      props: {
        label,
        name: fieldId,
        options,
        noNullOption: true,
        shrink: true
      }
    }
  })
  canAccessReportAudit && schema.push({
    id: 'reportAudit-view',
    field: 'Dropdown',
    props: {
      label: translations(`Permissions - Report Amendments`),
      name: 'reportAudit-view',
      options: [
        { value: 'ALLOW', label: 'Allow' },
        { value: 'DENY', label: 'Deny' },
        { value: 'DEFAULT', label: 'Default' }
      ],
      shrink: true
    }
  })
  schema.push({
    id: 'reportManagement-filterReports',
    field: 'Dropdown',
    props: {
      label: translations(`Permissions - Report Types`),
      name: 'reportManagement-filterReports',
      multiple: true,
      allowNullValue: true,
      options: [
        { value: 'medicine', label: 'Medicine' },
        { value: 'vaccine', label: 'Vaccine' },
        { value: 'devices', label: 'Devices' },
        { value: 'fake', label: 'Fake' },
        { value: 'defective', label: 'Defective' },
        { value: 'e-cigarette', label: 'E-cigarette' },
        { value: null, label: 'Uncategorised' }
      ],
      shrink: true
    }
  })
  canAccessResendTransmissions && schema.push({
    id: 'caseManagement-resendTransmissions',
    field: 'Dropdown',
    props: {
      label: translations(`Permissions - Resend Transmissions`),
      name: 'caseManagement-resendTransmissions',
      options: [
        { value: 'ALLOW', label: 'Allow' },
        { value: 'DENY', label: 'Deny' },
        { value: 'DEFAULT', label: 'Default' }
      ],
      shrink: true
    }
  })
  if(canAccessReassignReports) {
    schema.push({
      id: 'reportManagement-reassignReports',
      field: 'Dropdown',
      props: {
        label: translations(`Permissions - Reassign Reports`),
        name: 'reportManagement-reassignReports',
        options: [
          { value: 'ALLOW', label: 'Allow' },
          { value: 'DENY', label: 'Deny' },
          { value: 'DEFAULT', label: 'Default' }
        ],
        shrink: true
      }
    })
  }

  if (canEditPermissionsEditor) {
    schema.push({
      id: 'permissionsEditor-editPermissions',
      field: 'Dropdown',
      props: {
        label: translations(`Permissions - Edit Permissions Editor`),
        name: 'permissionsEditor-editPermissions',
        options: [
          { value: 'ALLOW', label: 'Allow' },
          { value: 'DENY', label: 'Deny' },
          { value: 'DEFAULT', label: 'Default' }
        ],
        shrink: true
      }
    })
  }

  if (canUploadResource) {
    schema.push({
      id: 'resources-uploadResource',
      field: 'Dropdown',
      props: {
        label: translations(`Permissions - Upload Resource`),
        name: 'resources-uploadResource',
        options: [
          { value: 'ALLOW', label: 'Allow' },
          { value: 'DENY', label: 'Deny' },
          { value: 'DEFAULT', label: 'Default' }
        ],
        shrink: true
      }
    })
  }

  if (canDeleteResource) {
    schema.push({
      id: 'resources-deleteResource',
      field: 'Dropdown',
      props: {
        label: translations(`Permissions - Delete Resource`),
        name: 'resources-deleteResource',
        options: [
          { value: 'ALLOW', label: 'Allow' },
          { value: 'DENY', label: 'Deny' },
          { value: 'DEFAULT', label: 'Default' }
        ],
        shrink: true
      }
    })
  }

  if (isCurrentUserAndSelectedUserOrgLead) {
    if (canViewPermissions && canEditPermissionsEditor) {
      schema = _.filter(schema, sch => sch.id === 'permissionsEditor-view' || sch.id === 'permissionsEditor-editPermissions')
    } else {
      schema = null
    }
  }

  return { schema }
}
