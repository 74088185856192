import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import SubmissionReportsModal from '../Modals/SubmissionReportsModal'

import { colors, translations } from '../../../../config'
import { styled, makeStyles } from '@material-ui/core/styles'
import modalService from '../../../../services/modalService'

const Container = styled('div')(() => ({
  position: 'relative',
  display: 'inline-block'
}))

const Badge = styled('button')(({ theme, disabled }) => ({
  height: 16,
  width: 16,
  borderRadius: 16,
  background: colors.success,
  position: 'absolute',
  top: -2,
  right: -5,
  zIndex: 1,
  border: '2px solid #ffffff',
  cursor: disabled ? 'default' : 'pointer',
  transition: 'transform .3s ease-in-out',
  '&:not([disabled]):hover, &:not([disabled]):focus': {
    transform: 'scale(1.15)'
  }
}))

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    marginBottom: 1
  }
}))

const SubmissionStatus = (props) => {
  const { children, isSent, receiverId, receiverName, receiverStatus, disabled, submission, hidden } = props
  const showBadge = _.includes(['NOT_SENT', 'PENDING', 'FAILED', 'TIMED_OUT', 'IN_REVIEW'], receiverStatus) && !hidden
  const tooltipClasses = useTooltipStyles()

  const onClick = () => {
    modalService.open({
      component: SubmissionReportsModal,
      submissionId: _.get(submission, 'id'),
      organisationId: _.get(submission, 'organisationId'),
      extReportId: _.get(submission, 'extReportId'),
      receiverId,
      receiverName,
      modalIndex: 1,
      zoomModal: true
    })
  }

  if (isSent && showBadge) {
    return (
      <Container>
        <Tooltip
          placement={'top'}
          title={translations('Submission has since been sent successfully')}
          classes={tooltipClasses}>
          <Badge disabled={disabled} onClick={onClick} />
        </Tooltip>
        {children}
      </Container>
    )
  }

  return children
}

SubmissionStatus.defaultProps = {
  isSent: false,
  disabled: false,
  hidden: false
}

SubmissionStatus.propTypes = {
  children: PropTypes.any,
  isSent: PropTypes.bool.isRequired,
  receiverStatus: PropTypes.oneOf([
    'NOT_SENT',
    'SUCCESS',
    'PENDING',
    'FAILED',
    'TIMED_OUT',
    'IN_REVIEW'
  ]),
  disabled: PropTypes.bool,
  submission: PropTypes.object,
  hidden: PropTypes.bool
}

export default SubmissionStatus
