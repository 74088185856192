export const CONFIGURE_COMMUNICATIONS_FORM = 'digitalStore/communicationTemplates/CONFIGURE_COMMUNICATIONS_FORM'
export const COMMUNICATIONS_TEMPLATE_FORM = 'digitalStore/communicationTemplates/COMMUNICATIONS_TEMPLATE_FORM'

export const GET_EMAIL_TEMPLATES = 'digitalStore/communicationTemplates/GET_EMAIL_TEMPLATES'
export const GET_ORGANISATION_EMAIL_TEMPLATES = 'digitalStore/communicationTemplates/GET_ORGANISATION_EMAIL_TEMPLATES'
export const GET_SYSTEM_EMAIL_TEMPLATES = 'digitalStore/communicationTemplates/GET_SYSTEM_EMAIL_TEMPLATES'
export const GET_ORGANISATION_EMAIL_TEMPLATE_BY_ID = 'digitalStore/communicationTemplates/GET_ORGANISATION_EMAIL_TEMPLATE_BY_ID'
export const GET_SYSTEM_EMAIL_TEMPLATE_BY_ID = 'digitalStore/communicationTemplates/GET_SYSTEM_EMAIL_TEMPLATE_BY_ID'
export const CREATE_ORGANISATION_EMAIL_TEMPLATE = 'digitalStore/communicationTemplates/CREATE_ORGANISATION_EMAIL_TEMPLATE'
export const CREATE_SYSTEM_EMAIL_TEMPLATE = 'digitalStore/communicationTemplates/CREATE_SYSTEM_EMAIL_TEMPLATE'
export const UPDATE_ORGANISATION_EMAIL_TEMPLATE = 'digitalStore/communicationTemplates/UPDATE_ORGANISATION_EMAIL_TEMPLATE'
export const UPDATE_SYSTEM_EMAIL_TEMPLATE = 'digitalStore/communicationTemplates/UPDATE_SYSTEM_EMAIL_TEMPLATE'
export const DELETE_ORGANISATION_EMAIL_TEMPLATE = 'digitalStore/communicationTemplates/DELETE_ORGANISATION_EMAIL_TEMPLATE'
export const DELETE_SYSTEM_EMAIL_TEMPLATE = 'digitalStore/communicationTemplates/DELETE_SYSTEM_EMAIL_TEMPLATE'
export const DELETE_SYSTEM_EMAIL_TEMPLATE_CONTENT_FOR_LANGUAGE = 'digitalStore/communicationTemplates/DELETE_SYSTEM_EMAIL_TEMPLATE_CONTENT_FOR_LANGUAGE'
export const UPDATE_DEFAULT_ORGANISATION_EMAIL_TEMPLATES = 'digitalStore/communicationTemplates/UPDATE_DEFAULT_ORGANISATION_EMAIL_TEMPLATES'

export const GET_SMS_TEMPLATES = 'digitalStore/communicationTemplates/GET_SMS_TEMPLATES'
export const GET_ORGANISATION_SMS_TEMPLATES = 'digitalStore/communicationTemplates/GET_ORGANISATION_SMS_TEMPLATES'
export const GET_ORGANISATION_SMS_TEMPLATE_BY_ID = 'digitalStore/communicationTemplates/GET_ORGANISATION_SMS_TEMPLATE_BY_ID'
export const CREATE_ORGANISATION_SMS_TEMPLATE = 'digitalStore/communicationTemplates/CREATE_ORGANISATION_SMS_TEMPLATE'
export const UPDATE_ORGANISATION_SMS_TEMPLATE = 'digitalStore/communicationTemplates/UPDATE_ORGANISATION_SMS_TEMPLATE'
export const DELETE_ORGANISATION_SMS_TEMPLATE = 'digitalStore/communicationTemplates/DELETE_ORGANISATION_SMS_TEMPLATE'
export const UPDATE_DEFAULT_ORGANISATION_SMS_TEMPLATES = 'digitalStore/communicationTemplates/UPDATE_DEFAULT_ORGANISATION_SMS_TEMPLATES'

export const GET_PUSH_TEMPLATES = 'digitalStore/communicationTemplates/GET_PUSH_TEMPLATES'
export const GET_ORGANISATION_PUSH_TEMPLATES = 'digitalStore/communicationTemplates/GET_ORGANISATION_PUSH_TEMPLATES'
export const GET_ORGANISATION_PUSH_TEMPLATE_BY_ID = 'digitalStore/communicationTemplates/GET_ORGANISATION_PUSH_TEMPLATE_BY_ID'
export const CREATE_ORGANISATION_PUSH_TEMPLATE = 'digitalStore/communicationTemplates/CREATE_ORGANISATION_PUSH_TEMPLATE'
export const UPDATE_ORGANISATION_PUSH_TEMPLATE = 'digitalStore/communicationTemplates/UPDATE_ORGANISATION_PUSH_TEMPLATE'
export const DELETE_ORGANISATION_PUSH_TEMPLATE = 'digitalStore/communicationTemplates/DELETE_ORGANISATION_PUSH_TEMPLATE'
export const UPDATE_DEFAULT_ORGANISATION_PUSH_TEMPLATES = 'digitalStore/communicationTemplates/UPDATE_DEFAULT_ORGANISATION_PUSH_TEMPLATES'