import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { translations } from '../../../config'


export const getAllFormViews = state => (state.formViews || {}).results || []

export const getIsLoading = state => _.get(state, 'formViews.results.status') === PENDING
export const getError = state => _.get(state, 'formViews.results.error')
export const getResults = state => _.get(state, 'formViews.results')

export const getFormViewById = (state) => (
  _.get(state, 'formViews.formViewById')
)

export const getNewSchemaBase = state => _.get(state, 'formViews.newSchemaBase')

export const getCurrentSchemaBase = state => _.get(state, 'formViews.formViewById')

export const getSelectedReportType = state => _.get(state, 'formViews.selectedReportType')

export const getFormSchemaUpdated = state => _.get(state, 'formViews.formSchemaUpdated', false)

export const getViewJSON = state => _.cloneDeep(_.get(state, 'formViews.viewJSON'))

export const getNotEditableJSON = state => _.get(state, 'formViews.notEditable')

export const getReportConfigurationTranslationCSV = state => _.get(state, 'formViews.translationsCSV')

export const clearForm = state => state.form = {}

export const getReportConfigViewJSON = state => {
  const defaultViewJSON = _.get(getNewSchemaBase(state), 'viewJSON')
  const currentViewJSON = _.get(getFormViewById(state), 'formViewVersions[0].viewJSON')
  return defaultViewJSON || currentViewJSON
}

export const getCurrentFormViewId = createSelector(
  getFormViewById,
  (formView) => {
    return _.get(formView, 'id')
  }
)

export const getCurrentFormViewDetails = createSelector(
  getFormViewById,
  (formView) => {
    return _.get(formView, 'details', {
      audienceId: 1,
      showNullFlavours: true
    })
  }
)

export const getCurrentFormViewCategory = createSelector(
  getFormViewById,
  getNewSchemaBase,
  (formView, newSchema) => {
    const newCategory = _.get(newSchema, 'category')
    return _.get(formView, 'category', newCategory)
  }
)

export const getCurrentFormViewSubCategory = createSelector(
  getFormViewById,
  getNewSchemaBase,
  (formView, newSchema) => {
    const newCategory = _.get(newSchema, 'subCategory')
    return _.get(formView, 'subCategory', newCategory)
  }
)

export const getCurrentFormViewSources = createSelector(
  getFormViewById,
  (formView) => {
    return _.get(formView, 'sources')
  }
)

export const getSchemaAudiences = createSelector(
  getNewSchemaBase,
  getCurrentSchemaBase,
  (newSchema, currentSchema) => {
    return _.get(newSchema, 'audience') || _.get(currentSchema, 'formSchema.audience')
  }
)

export const getAudiencesAsDropdown = createSelector(
  getSchemaAudiences,
  (audiences) => {
    return _.map(audiences, (audience) => ({
      value: audience.id,
      label: audience.name
    }))
  }
)

export const getCurrentFormViewFormSchemaName = createSelector(
  [getFormViewById, getNewSchemaBase],
  (formView, schemaBase) => {
    const baseSchemaName = _.get(schemaBase, 'schemaName')
    return _.get(formView, 'formSchema.name', baseSchemaName)
  }
)

export const getCurrentFormViewVersionJSON = createSelector(
  getFormViewById,
  (formView) => {
    return _.get(formView, 'formViewVersions.0.viewJSON')
  }
)

export const getCurrentFormViewVersionMetaFields = createSelector(
  getFormViewById,
  (formView) => {
    const [schemaLibraryVersion, schemaVersionJsonHash] = _.at(formView, ['formViewVersions.0.schemaLibraryVersion', 'formViewVersions.0.schemaVersionJsonHash'])
    const formViewVersionMeta = {
      schemaLibraryVersion,
      schemaVersionJsonHash
    }
    return formViewVersionMeta
  }
)

export const getSelectedFormViewId  = createSelector(
  getSelectedReportType,
  (reportType) => {
    return _.get(reportType, 'formViewVersions.0.formViewId')
  }
)

export const getSelectedSchemaName  = createSelector(
  getSelectedReportType,
  (reportType) => {
    return _.get(reportType, 'name')
  }
)

export const getSelectedFormSchemaName = createSelector(
  getSelectedReportType,
  (reportType) => {
    return _.get(reportType, 'formSchema.name')
  }
)


export const getSelectedFormSchemaId = createSelector(
  getSelectedReportType,
  (reportType) => {
    return _.get(reportType, 'formSchema.id') || _.get(reportType, 'id')
  }
)

export const categoryOptions = [
  { value: 'medicine', label: translations('FormView - Category Options - Medicines') },
  { value: 'vaccine', label: translations('FormView - Category Options - Vaccines') },
  { value: 'devices', label: translations('FormView - Category Options - Devices') },
  { value: 'fake', label: translations('FormView - Category Options - Fake') },
  { value: 'defective', label: translations('FormView - Category Options - Defective') },
  { value: 'e-cigarette', label: translations('FormView - Category Options - E-cigarette') }
]

export const versionOptions = [
  { value: '1', label: translations('FormView - Version Options - Version 1') },
  { value: '2', label: translations('FormView - Version Options - Version 2') }
]

export const observeStudyTypeOptions = [
  { value: 1, label: translations('FormView - Observe Study Type Options - Clinical trials') },
  { value: 2, label: translations('FormView - Observe Study Type Options - Individual patient use') },
  { value: 3, label: translations('FormView - Observe Study Type Options - Other studies') }
]

export const reportTypeOptions = [
  { value: '1', label: translations('FormView - Report Type Options - Spontaneous') },
  { value: '2', label: translations('FormView - Report Type Options - Report from study') },
  { value: '3', label: translations('FormView - Report Type Options - Other') },
  { value: '4', label: translations('FormView - Report Type Options - Not available to sender (unknown)') }
]

export const timeframeOptions = [
  { value: '1', label: translations('FormView - Timeframe Options - 1 Day') },
  { value: '3', label: translations('FormView - Timeframe Options - 3 Days') },
  { value: '7', label: translations('FormView - Timeframe Options - 7 Days') },
  { value: '14', label: translations('FormView - Timeframe Options - 14 Days') },
  { value: '21', label: translations('FormView - Timeframe Options - 21 Days') }
]

export const subCategoryOptions = [
  { value: 'equipment', label: translations('FormView - Sub Category Options - Equipment') },
  { value: 'ivd', label: translations('FormView - Sub Category Options - IVD') },
  { value: 'implant', label: translations('FormView - Sub Category Options - Implant (not breast implant)') },
  { value: 'breastImplant', label: translations('FormView - Sub Category Options - Breast Implant') },
  { value: 'software', label: translations('FormView - Sub Category Options - Software (incl. AI)') }
]

export const reportSendingMethodOptions = [
  { value: false, label: translations('FormView - Report Sending Method Options - Automatically send') }, // 'holdForReview' in organisationRecievers is either true or false
  { value: true, label: translations('FormView - Report Sending Method Options - Review and send') }
]
